<template>
  <div class="container">
    <a href="/">
      <span>
        <img class="logo-img" src="../assets/mcylogo.png" alt="" />
        <img class="title-img" :src="getTitle()" alt="" />
      </span>
    </a>
    <div class="card">
      <input
        @change="toggleTheme"
        id="checkbox"
        type="checkbox"
        class="switch-checkbox"
      />
      <label for="checkbox" class="switch-label">
        <div
          class="switch-toggle"
          :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
        ></div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data() {
    return {
      userTheme: "light-theme",
    };
  },
  methods: {
    getTitle() {
      const activeTheme = this.userTheme;

      if (activeTheme == "dark-theme") {
        return require("@/assets/mcytitle.png");
      } else {
        return require("@/assets/mcytitle-light.png");
      }
    },
    toggleTheme() {      
      const activeTheme = this.userTheme;

      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    setTheme(theme) {
      this.userTheme = theme;
      document.documentElement.className = theme;
      this.$emit('theme-change', theme);
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 30px;
  position: relative;
  z-index: 100 !important;
  display: flex;
}
.logo-img {
  height: 30px !important;
  margin-right: 20px;
}
.title-img {
  height: 13px !important;
}

.card {
  text-align: center;
  border: 0;
  background-color: transparent;
  margin-right: 0;
  margin-left: auto;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  /* for width, use the standard element-size */
  width: var(--element-size);

  /* for other dimensions, calculate values based on it */
  border-radius: var(--element-size);

  padding: calc(var(--element-size) * 0.1);
  font-size: calc(var(--element-size) * 0.3);
  height: calc(var(--element-size) * 0.4);

  align-items: center;
  background: var(--background-color-secondary);
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background 0.5s ease;
  justify-content: space-between;
  z-index: 1;
}

.switch-toggle {
  position: absolute;
  background-color: var(--background-color-primary);
  border-radius: 50%;
  top: 1.5px;
  left: 4px;
  height: calc(var(--element-size) * 0.35);
  width: calc(var(--element-size) * 0.35);
  transform: translateX(0);
  transition: transform 0.3s ease, background-color 0.5s ease;
}

.switch-toggle-checked {
  transform: translateX(calc(var(--element-size) * 0.6)) !important;
  left: 0;
}
</style>