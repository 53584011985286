import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue3 from 'bootstrap-vue-3';
import Vue3Lottie from 'vue3-lottie';
import VueLazyload from 'vue-lazyload';
import 'vue3-lottie/dist/style.css'
import "@/assets/sass/style.scss";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.use(Vue3Lottie);
app.use(VueLazyload);

app.mount('#app');