<template>
  <div class="container">
    <div class="regular">
      <div class="row top-container justify-content-center">
        <div
          class="col-12 col-md-5 col-lg-5 col-xl-5 title-container order-md-1 order-2"
        >
          <h2 class="first-title">Cheap compute</h2>
          <svg
            width="81"
            height="16"
            viewBox="0 0 81 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
          >
            <path
              d="M80.7071 8.70711C81.0976 8.31658 81.0976 7.68342 80.7071 7.29289L74.3431 0.928932C73.9526 0.538408 73.3195 0.538408 72.9289 0.928932C72.5384 1.31946 72.5384 1.95262 72.9289 2.34315L78.5858 8L72.9289 13.6569C72.5384 14.0474 72.5384 14.6805 72.9289 15.0711C73.3195 15.4616 73.9526 15.4616 74.3431 15.0711L80.7071 8.70711ZM0 9H80V7H0V9Z"
              :fill="this.theme == 'dark-theme' ? 'white' : 'black'"
            />
          </svg>
          <h2>God machines</h2>
          <svg
            width="81"
            height="16"
            viewBox="0 0 81 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
          >
            <path
              d="M80.7071 8.70711C81.0976 8.31658 81.0976 7.68342 80.7071 7.29289L74.3431 0.928932C73.9526 0.538408 73.3195 0.538408 72.9289 0.928932C72.5384 1.31946 72.5384 1.95262 72.9289 2.34315L78.5858 8L72.9289 13.6569C72.5384 14.0474 72.5384 14.6805 72.9289 15.0711C73.3195 15.4616 73.9526 15.4616 74.3431 15.0711L80.7071 8.70711ZM0 9H80V7H0V9Z"
              :fill="this.theme == 'dark-theme' ? 'white' : 'black'"
            />
          </svg>
          <h2>Eternal life</h2>
        </div>
        <div
          class="col-9 col-md-5 col-lg-5 col-xl-5 anim-container order-md-2 order-1"
        >
          <Vue3Lottie
            :style="showDark"
            class="anim"
            :animationData="NetworkWhite"
            :speed="1"
          />
          <Vue3Lottie
            :style="showLight"
            class="anim"
            :animationData="NetworkBlack"
            :speed="1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkWhite from "../animations/network-white.json";
import NetworkBlack from "../animations/network-black.json";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Top",
  props: {
    theme: String,
  },
  data() {
    return {
      NetworkWhite,
      NetworkBlack,
    };
  },
  computed: {
    showLight() {
      return {
        display: this.theme != "dark-theme" ? "initial" : "none",
      };
    },
    showDark() {
      return {
        display: this.theme == "dark-theme" ? "initial" : "none",
      };
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--text-primary-color);
  font-family: "Trail", sans-serif;
  font-weight: 800;
  font-size: 45px !important;
  text-align: center;
}

h2 {
  color: var(--text-primary-color);
  font-family: "Trail", sans-serif;
  font-weight: 500;
  font-size: 60px !important;
}
h3 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 50px !important;
  text-align: right;
}

h4 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 25px !important;
  text-align: right;
  line-height: 40px;
}

h5 {
  text-transform: uppercase;
  font-size: 23px !important;
  color: var(--text-primary-color);
  text-align: center;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 20px !important;
  color: var(--text-primary-color);
}

.arrow {
  margin-top: 30px;
  margin-bottom: 30px;
}

.top-container {
  margin-top: 100px;
}

.middle-container-two,
.middle-container-three {
  margin-top: 350px;
}

.title-container {
  z-index: 10 !important;
}

.first-title {
  margin-top: 70px;
}

.section-title {
  text-align: left;
  margin-top: 120px;
  line-height: 80px;
}

.mobile {
  display: none;
}

.col-6 {
  margin-bottom: 50px;
}

.h2-mobile {
  padding-top: 70px;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  .anim {
    -moz-transform: scale(0.1);
    transform: scale(0.1);
    margin-top: 0px !important;
  }

  h2 {
    font-size: 40px !important;
  }

  .title-container {
    text-align: center;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .middle-container-two,
  .middle-container-three {
    margin-top: 200px;
  }

  h1 {
    font-size: 35px !important;
  }

  h2 {
    font-size: 40px !important;
  }

  h3 {
    font-size: 35px !important;
  }
  h4 {
    font-size: 20px !important;
    line-height: 30px;
    padding-left: 50px;
  }
  h5 {
    font-size: 12px !important;
  }

  .arrow {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/* large */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  h2 {
    font-size: 50px !important;
  }
  h1 {
    font-size: 45px !important;
  }
  h3 {
    font-size: 35px !important;
  }
  h4 {
    font-size: 20px !important;
    line-height: 30px;
    padding-left: 50px;
  }
  h5 {
    font-size: 12px !important;
  }
}
</style>