<template>
    <div class="resource-view-bg">
        <h2>{{title}}</h2>
        <p>{{description}}</p>
        <div class="download-container">
            <img class="icon" src="../assets/triangle.svg" alt="">
            <button class="download btn btn-outline-light"><a href="https://lajosdeme.notion.site/Mercury-Protocol-Litepaper-25d71ebe3ca241ffb9e02008d9b4c2e4" target="_blank">Open</a></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResourceView",
    props: {
        title: String,
        description: String,
        link: String,
    }
}
</script>

<style scoped>
.resource-view-bg {
    background-color: var(--background-color-secondary);;
}

.icon {
    filter: var(--filter);
}

.download-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content:space-between;
}

h2 {
    color: var(--text-primary-color);
    font-family: 'Anonymous Pro', monospace;
    font-weight: 400;
    padding-top: 30px;
    padding-left: 30px;
}

p {
    color: var(--text-primary-color);
    font-family: 'Anonymous Pro', monospace;
    font-weight: 200;
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: 30px;
    margin-right: 30px;
}

button {
    color: var(--text-primary-color);
    border-color: var(--text-primary-color);;
}
button:hover {
    background-color: var(--background-color-primary);
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

/* extra small & small */
@media screen and (max-width: 767px) {
    .resource-view-bg {
        margin-bottom: 50px;
    }
    h2 {
        font-size: 25px;
    }
    p {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 14px;
    }
}
</style>