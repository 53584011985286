<template>
  <a
    href="mailto:founders@mercuryprotocol.io"
    class="email-link"
    title="founders@mercuryprotocol.io"
  >
    <svg height="32" width="32" viewBox="0 0 24 24" class="email-logo">
      <path
        :fill="theme == 'dark-theme' ? 'white' : 'black'"
        d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "MailLink",
  props: {
    theme: String,
  },
};
</script>

<style scoped>
.email-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #24292e;
  text-decoration: none;
  transition: color 0.2s ease;
}

.email-link:hover {
  color: #0366d6;
}

.email-logo {
  transition: transform 0.2s ease;
}

.email-link:hover .email-logo {
  transform: scale(1.1);
}
</style>