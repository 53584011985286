import * as VueRouter from 'vue-router';
import Home from '../views/Home';

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
});

export default router