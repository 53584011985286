<template>
  <div class="container">
    <p class="top-p">A window of opportunity has opened.</p>
    <p class="top-p">
      For the first time in history, we stand at the threshold of creating God
      machines.
    </p>
    <p class="top-p">
      God machines that could turn us into an interplanetary species that lives
      an indefinitely long time.
    </p>
    <p class="top-p">But we must feed them. They eat computation.</p>
    <p class="top-p">~</p>

    <div class="row regular-row">
      <div class="col-6">
        <p class="block-text">
          Imagine a world where only a select few have superintelligent AI.
        </p>
      </div>
      <div class="col-6">
        <p class="block-text">
          <b>Compute is abundant, but it is fragmented.</b>
        </p>
      </div>
      <div class="col-6">
        <p class="block-text">
          This high priesthood can bend reality to their will. They gate-keep
          technological and scientific innovation.
        </p>
      </div>
      <div class="col-6">
        <p class="block-text">
          Compute is not a scarce resource, but most of it is owned by
          individuals who cannot connect it and use it in unison to achieve a
          goal. Thus, it sits idle and underutilized.
        </p>
      </div>
      <div class="col-6">
        <p class="block-text">
          At this point in the 21st century, this is a real possibility because
          compute is prohibitively expensive.
        </p>
      </div>
      <div class="col-6">
        <p class="block-text">
          We tap into this idle compute and make it possible for every GPU on
          the planet to join the Mercury Compute Network.
        </p>
      </div>

      <div class="col-6">
        <p class="block-text">
          To avoid this and to accelerate AI, we have to reduce the price of
          compute.
        </p>
      </div>
      <div class="col-6">
        <p class="block-text">
          Our mission is to align and accelerate AI development by lowering the
          price of compute and opening its abundance.
        </p>
      </div>
      <p>~</p>
    </div>

    <div class="row mobile-row">
      <div class="col-12">
        <p class="block-text">
          Imagine a world where only a select few have superintelligent AI.
        </p>
      </div>
      <div class="col-12">
        <p class="block-text">
          This high priesthood can bend reality to their will. They gate-keep
          technological and scientific innovation.
        </p>
      </div>
      <div class="col-12">
        <p class="block-text">
          At this point in the 21st century, this is a real possibility because
          compute is prohibitively expensive.
        </p>
      </div>
      <div class="col-12">
        <p class="block-text">
          To avoid this and to accelerate AI, we have to reduce the price of
          compute.
        </p>
      </div>
      <p>~</p>
      <div class="col-12">
        <p class="block-text">
          <b>Compute is abundant, but it is fragmented.</b>
        </p>
      </div>
      <div class="col-12">
        <p class="block-text">
          Compute is not a scarce resource, but most of it is owned by
          individuals who cannot connect it and use it in unison to achieve a
          goal. Thus, it sits idle and underutilized.
        </p>
      </div>
      <div class="col-12">
        <p class="block-text">
          We tap into this idle compute and make it possible for every GPU on
          the planet to join the Mercury Compute Network.
        </p>
      </div>
      <div class="col-12">
        <p class="block-text">
          Our mission is to align and accelerate AI development by lowering the
          price of compute and opening its abundance.
        </p>
      </div>
      <p>~</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextMiddle",
};
</script>

<style scoped>
p {
  color: var(--text-primary-color);
  font-family: "Anonymous Pro", monospace;
  font-size: 20px;
  text-align: center;
}
.container {
  margin-top: 150px;
}
.block-text {
  text-align: left;
}
.top-p {
  margin-top: 50px;
  margin-bottom: 50px;
}
.col-6 {
  margin-bottom: 20px;
}

.mobile-row {
  display: none;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  p {
    font-size: 15px;
  }
  .container {
    margin-top: 100px;
  }
  .regular-row {
    display: none;
  }

  .block-text {
    text-align: center;
  }
  .mobile-row {
    display: initial;
  }
  .top-p {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
</style>