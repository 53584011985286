<template>
  <div class="container resources-container">
    <div class="row justify-content-center">
      <div class="col-10">
        <h1>Resources</h1>
        <ResourceView
          title="Technical Litepaper"
          description="This paper gives a brief technical introduction to the Mercury Protocol, a decentralized and scalable compute network running on blockchain. It describes the whole architecture and goes into detail on the software and hardware components."
          link=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import ResourceView from "./ResourceView.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Resources",
  components: {
    ResourceView,
  },
};
</script>
<style scoped>
h1 {
  color: var(--text-primary-color);
  font-family: "Trail", sans-serif;
  font-weight: 800;
  font-size: 55px !important;
  margin-bottom: 50px;
}

.resources-container {
  margin-top: 50px;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  .col-2 {
    display: none;
  }
  .col-12 {
    width: 95%;
  }
  .resources-container {
    margin-top: 50px;
  }
  h1 {
    font-size: 40px !important;
    margin-bottom: 20px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 45px !important;
  }
}

/* large */
@media screen and (min-width: 991px) and (max-width: 1200px) {
  h1 {
    font-size: 55px !important;
  }

  .resources-container {
    margin-top: 50px;
  }
}
</style>