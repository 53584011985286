<template>
  <div class="container footer-container">
    <div class="row">
      <div class="col-6">
        <div class="social-links">
          <MailLink :theme="theme" />
          <GithubLink :theme="theme" />
          <WarpcastLink :theme="theme" />
          <XLink :theme="theme" />
        </div>
      </div>
      <div class="col-6">
        <div class="copyright">
          <h4>
            Mercury Protocol {{ new Date().getFullYear() }} All rights reserved.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GithubLink from "./GithubLink.vue";
import MailLink from "./MailLink.vue";
import WarpcastLink from "./WarpcastLink.vue";
import XLink from "./XLink.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  props: {
    theme: String,
  },
  components: {
    GithubLink,
    MailLink,
    WarpcastLink,
    XLink,
  },
  data() {
    return {
      userTheme: String,
    };
  },
  mounted() {
    this.userTheme = this.theme;

  },
};
</script>

<style scoped>
.container {
  margin-top: 300px;
}
h4 {
  color: var(--text-primary-color);
  text-align: left;
  font-family: "Anonymous Pro", monospace;
  font-size: 15px;
  text-align: right;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}
.footer-container {
  position: relative;
  margin-bottom: 50px;
}

.link-container {
  z-index: 1;
}

.social-links {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 45px !important;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }

  .col-2 {
    display: none;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 15px;
  }
}
</style>

<!-- 
        <p><a href="mailto:founders@mercuryprotocol.io"> E-mail</a></p>
        <p>
          <a href="https://twitter.com/mcyprotocol" target="_blank"> Twitter</a>
        </p>
-->