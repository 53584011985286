<template>
  <div>
    <Header @theme-change="handleThemeChange" />
    <Top :theme="theme" />
    <TextMiddle />
    <Resources />
    <CTA />
    <Footer :theme="theme" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Top from "../components/Top.vue";
import CTA from "../components/CTA.vue";
import Resources from "../components/Resources.vue";
import Footer from "../components/Footer.vue";
import TextMiddle from "@/components/TextMiddle.vue";


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Header,
    Top,
    CTA,
    Resources,
    Footer,
    TextMiddle,
  },
  data() {
    return {
      theme: 'light-theme'
    }
  },
  methods: {
    handleThemeChange(theme) {
      this.theme = theme;
    },
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html,
body {
  background-color: var(--background-color-primary) !important;
  overflow-x: hidden !important;
}

:root {
  --background-color-primary: white;
  --background-color-secondary: #EADDDD;
  --background-color-tertiary: black;
  --text-primary-color: black;
  --text-secondary-color:white;
  --accent-color: #002fa8;
  --element-size: 4rem; /* size of theme toggle */
  --toggle-color: #002fa8;
  --filter: invert(1);
}

:root.dark-theme {
  --background-color-primary: black;
  --background-color-secondary: #242323;
  --background-color-tertiary: white;
  --text-primary-color: white;
  --text-secondary-color:black;
  --accent-color: red;
  --element-size: 4rem; /* size of theme toggle */
  --toggle-color: #002fa8;
  --filter: initial;
}
</style>